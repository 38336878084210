import { createRouter, createWebHistory } from "vue-router";
import Home from '@/presentation/pages/Home.vue';
import Courses from '@/presentation/pages/Courses.vue';
import CoursesList from '@/presentation/pages/CoursesList.vue';
import CourseSidebar from '@/presentation/pages/CourseSidebar.vue';
import About from '@/presentation/pages/About.vue';
import Instructor from '@/presentation/pages/Instructor.vue';
import InstructorDetails from '@/presentation/pages/InstructorDetails.vue';
import EventDetails from '@/presentation/pages/EventDetails.vue';
import Cart from '@/presentation/pages/Cart.vue';
import Checkout from '@/presentation/pages/Checkout.vue';
import SignIn from '@/presentation/pages/SignIn.vue';
import ErrorPage from '@/presentation/pages/ErrorPage.vue';
import Contact from '@/presentation/pages/Contact.vue';
import WatchCourse from '@/presentation/pages/Course.vue';
import NewCourses from '@/presentation/pages/NewCourse.vue';
import InstructorRegister from '@/presentation/pages/InstructorRegister.vue';
import SignUp from '@/presentation/pages/SignUp.vue';
import CourseDetails from '@/presentation/pages/CourseDetails.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: Home,
        },
        {
            path: '/home',
            component: Home,
        },
        {
            path: '/courses',
            component: Courses,
        },
        {
            path: '/courses-list',
            component: CoursesList,
        },
        {
            path: '/courses-sidebar',
            component: CourseSidebar,
        },
        {
            path: '/course-details/:id',
            component: CourseDetails,
        },
        {
            path: '/course/watch',
            component: WatchCourse
        },
        {
            path: '/about',
            component: About,
        },
        {
            path: '/instructor',
            component: Instructor,
        },
        {
            path: '/instructor-details',
            component: InstructorDetails,
        },
        {
            path: '/event-details',
            component: EventDetails,
        },
        {
            path: '/cart',
            component: Cart,
        },
        {
            path: '/checkout',
            component: Checkout,
        },
        {
            path: '/login',
            component: SignIn,
        },
        {
            path: '/register',
            component: SignUp,
        },
        {
            path: '/instructor-register',
            component: InstructorRegister,
        },
        {
            path: '/errorPage',
            component: ErrorPage,
        },
        {
            path: '/contact',
            component: Contact,
        },
        {
            path: '/new-course',
            component: NewCourses,
        }
    ]
})

router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0)
});

export default router