<style>
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
</style>
<template>
   <CouponArea/>
    <section class="checkout-area pb-70">
      <div class="container">
            <div class="row">
                  <div class="col-lg-6">
                     <div class="checkbox-form">
                       <h3>{{ $t('billing.billingDetails') }}</h3>
                        <div class="row">
                              <div class="col-md-12">
                                 <div class="country-select">
                                    <label>Country <span class="required">*</span></label>
                                   <select v-model="selectedCountry">
                                     <option v-for="country in countries" :key="country.code" :value="country.name">
                                       {{ country.name }}
                                     </option>
                                   </select>
                                 </div>
                              </div>

                          <div class="col-md-6">
                            <div class="checkout-form-list">
                              <label>{{ $t('signUp.firstName') }} <span class="required">*</span></label>
                              <input type="text" v-model="formData.firstName" placeholder="First Name"/>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="checkout-form-list">
                              <label>{{ $t('signUp.lastName') }} <span class="required">*</span></label>
                              <input type="text" v-model="formData.lastName" placeholder="Last Name"/>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="checkout-form-list">
                              <label>{{$t('billing.email')}} <span class="required">*</span></label>
                              <input type="email" v-model="formData.email" placeholder="Email"/>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="checkout-form-list">
                              <label>{{$t('billing.phone')}}  <span class="required">*</span></label>
                              <input type="text" v-model="formData.phone" placeholder="+1 903 343443"/>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div :class="['checkout-form-list create-acc', { 'highlight':  showHighlight }]">
                              <input type="checkbox" v-model="formData.createAccount" id="cbox"/>
                              <label>{{$t('billing.createAccountTitle')}}</label>
                            </div>
                            <div id="cbox_info" :class="`checkout-form-list ${showAcc ? 'show-create-acc' : 'create-account'}`">
                              <p>{{$t('billing.createAccountDetail')}}</p>
                              <label>Account password <span class="required">*</span></label>
                              <input type="password" v-model="formData.password" placeholder="password"/>
                            </div>
                          </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="your-order mb-30 ">
                       <h3>{{ $t('billing.yourOrder') }}</h3>
                        <div class="your-order-table table-responsive">
                              <table>
                                 <thead>
                                    <tr>
                                      <th class="product-name">{{ $t('billing.products') }}</th>
                                       <th class="product-total">Total</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                 <tr class="cart_item" v-for="item in items" :key="item.courseId">
                                       <td class="product-name">
                                         {{ item?.courseName }}<strong class="product-quantity"> × {{ item.quantity }}</strong>
                                       </td>
                                       <td class="product-total">
                                         <span class="amount">${{ item?.total?.toFixed(2) }}</span>
                                       </td>
                                 </tr>
                                 </tbody>
                                 <tfoot>

                                    <tr class="order-total">
                                       <th>{{$t('billing.totalOrder')}}</th>
                                       <td><strong><span class="amount">${{ formattedCartTotal }}</span></strong>
                                       </td>
                                    </tr>
                                 </tfoot>
                              </table>
                        </div>

                        <div class="payment-method">
                           <div class="accordion" id="checkoutAccordion">
<!--                              <div class="accordion-item">
                                 <h2 class="accordion-header" id="checkoutOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#bankOne" aria-expanded="true" aria-controls="bankOne">
                                    Direct Bank Transfer
                                    </button>
                                 </h2>
                                 <div id="bankOne" class="accordion-collapse collapse show" aria-labelledby="checkoutOne" data-bs-parent="#checkoutAccordion">
                                    <div class="accordion-body">
                                    Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.
                                    </div>
                                 </div>
                              </div>
                              <div class="accordion-item">
                                 <h2 class="accordion-header" id="paymentTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#payment" aria-expanded="false" aria-controls="payment">
                                    Cheque Payment
                                    </button>
                                 </h2>
                                 <div id="payment" class="accordion-collapse collapse" aria-labelledby="paymentTwo" data-bs-parent="#checkoutAccordion">
                                    <div class="accordion-body">
                                    Please send your cheque to Store Name, Store Street, Store Town, Store
                                    State / County, Store
                                    Postcode.
                                    </div>
                                 </div>
                              </div>-->
                              <div class="accordion-item">
                                 <h2 class="accordion-header" id="paypalThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#paypal" aria-expanded="false" aria-controls="paypal">
                                      Stripe <i class="fa fa-stripe"></i>
                                    </button>
                                 </h2>
                                 <div id="paypal" class="accordion-collapse collapse" aria-labelledby="paypalThree" data-bs-parent="#checkoutAccordion">
                                    <div class="accordion-body">
                                      <div class="stripe-form">
                                        <form id="payment-form" @submit.prevent="executePayment">
                                          <label for="card-element">
                                           {{$t('billing.creditOrDebit')}}
                                          </label>
                                          <div id="card-element"><!-- Aquí se renderizará la tarjeta --></div>
                                          <button id="submit" v-if="showPayButton" class="e-btn e-btn-border mt-3 w-100">{{$t('billing.pay')}}
                                          </button>
                                          <div id="error-message" class="error-message"><!-- Mensajes de error --></div>
                                        </form>
                                      </div>
                                    </div>
                                 </div>
                              </div>
                              </div>
                              <div class="order-button-payment mt-20">
                                <button type="button" @click="submitOrder" class="e-btn e-btn-border" v-if="!showPayButton">
                                  {{ $t('billing.placeOrder') }}
                                </button>
                              </div>
                        </div>
                     </div>
                  </div>
            </div>
      </div>
   </section>
</template>

<script>
import CouponArea from './CouponArea.vue';
import { mapGetters, useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { PaymentsUseCase } from '@/core/usecases/payments.usecase';
import { key } from '@/infrastructure/di/store';
import { AuthUseCase } from '@/core/usecases/auth.usecase';
import { loadStripe } from '@stripe/stripe-js';

export default {
   name:'CheckoutArea',
   components:{
      CouponArea,
   },
  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
   data () {
      return {
        toast: useToast(),
        useCase: new PaymentsUseCase(this.store),
        userUseCase: new AuthUseCase(this.store),
         showAcc:false,
         shipBox:false,
        countries: [],
        selectedCountry: '',
        showHighlight: false,
        processing: false,
        elements: null,
        stripe: null,
        card: null,
        showPayButton: false,
        clientSecret: null,
        formData: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          password: '',
          createAccount: false,
          items: [],
        },
      }
   },
  methods: {
    async fetchCountries() {
      const response = await fetch('https://restcountries.com/v3.1/all?fields=cca2,name,flags');
      const data = await response.json();
      this.countries = data.map((country) => ({
        name: country.name.common,
        code: country.cca2,
        flag: country.flags.svg
      }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    showCreateAcc() {
      return this.showAcc = !this.showAcc
    },

    async validateUser() {
      try {
        console.log('valid')
        const isValid = await this.userUseCase.emailExists(this.formData.email);
        if (!isValid) {
          this.showHighlight = true;
          this.toast.info('User not Found, Please click on Create Account CheckBox Button');
        }
        return isValid;
      } catch (error) {
        if (error?.status === 400) {
          switch (error?.data?.message) {
            case 'EMAIL_NOT_VALID_ERROR':
              this.toast.error('Email not valid, please enter a valid email');
              break;
            default:
              this.toast.error('User not Found, Please click on Create Account CheckBox Button');
          }
        }else{
          this.toast.error('User not Found, Please click on Create Account CheckBox Button');
        }
      }
      return false;
    },
    async submitOrder() {
      // Valida los campos requeridos
      if(this.cartTotal <=0){
        this.toast.info('Please add items to cart.');
        return;
      }

      this.showHighlight = false;
      if (
        !this.formData.firstName ||
        !this.formData.lastName ||
        !this.formData.email ||
        !this.formData.phone
      ) {
        this.toast.error('Please fill in all required fields.');
        return;
      }

      let creatorId = '';
      let creatorName = '';
      if(this.items.length > 0){
        creatorId = this.items[0].creatorId;
        creatorName = this.items[0].creatorName;
      }
      const details = this.items.map((item) => ({
        courseId: item.courseId,
        courseName: item.courseName,
        quantity: item.quantity,
        price: item.price,
        total: item.total,
      }));

      const payment = {
        details,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
        phone: this.formData.phone,
        country: this.selectedCountry,
        createAccount: this.formData.createAccount,
        totalToPay: this.cartTotal,
        creatorId: creatorId,
        creatorName: creatorName,
      };

      try {
        if(this.formData.createAccount || await this.validateUser()) {
          const paymentResponse = await this.useCase.createPayment(payment);
          console.log(paymentResponse);
          if (!paymentResponse.paymentId) {
            throw new Error('Failed to place order.');
          }
          this.openAccordion('paypal');
          await this.loadStripeView(paymentResponse.publicKey, paymentResponse.externalClientSecret);
          this.toast.success('Order Placed, please complete payment.');
          this.resetForm();
        }
      } catch (error) {

        switch (error?.status) {
          case 400:
            this.toast.error('User not Found, Please click on Create Account CheckBox Button');
            break;
          case 401:
            this.toast.error('Unauthorized');
            break;
          case 403:
            this.toast.error('Forbidden');
            break;
          case 404:
            this.toast.error('Not Found');
            break;
          case 500:
            this.toast.error('Internal Server Error');
            break;
          default:
            this.toast.error('Error placing order:', error);
        }
      }
    },
    resetForm() {
      this.formData = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        createAccount: false,
        items: [],
      };
      this.selectedCountry = '';
    },
    async executePayment() {
      this.processing = true;
      try {
        const { paymentIntent, error } = await this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: `${this.formData.firstName} ${this.formData.lastName}`,
              email: this.formData.email,
              address: {
                postal_code: '00000',
              },
            },
          },
        });
        console.log(paymentIntent)
        if (error) {
          document.getElementById('error-message').textContent = error.message;
          return;
        } else {
          if (paymentIntent.status === 'succeeded') {
            paymentIntent.confirmation_method;
            paymentIntent.amount;

            const confirmResponse = await this.useCase.confirmPayment(
              paymentIntent.status, paymentIntent.id, paymentIntent.client_secret,
              paymentIntent.confirmation_method,
              paymentIntent.amount);
            if (confirmResponse) {
              this.toast.success('Payment successful, please Log In Our Platform Now!');
              this.resetForm();
              await this.store.dispatch('shoppingCart/clearCart');
              window.location.href = 'https://consumer.bullfy.com';
            }
          }
        }
        this.processing = false;
        this.showPayButton = false;
      } catch (e) {
        this.toast.error('Error confirming payment:', e.message);
      }
    },
    async loadStripeView(publicKey, clientSecret) {
      try {
        this.showPayButton = true
        // Carga la clave pública de Stripe
        this.stripe = await loadStripe(publicKey);
        this.clientSecret = clientSecret;
        this.elements = this.stripe.elements();
        this.card = this.elements.create('card', {
          hidePostalCode: true
        });
        this.card.mount('#card-element');
      } catch (error) {
        console.error('Payment error:', error.message);
        this.toast.error('Payment error:', error.message);
      }
    },
    openAccordion(accordionId) {
      const accordion = document.getElementById(accordionId);
      if (accordion) {
        accordion.classList.add('show');
      }
    },
  },
  computed: {
    ...mapGetters('shoppingCart', ['items', 'showMiniCart', 'cartTotal', 'totalItems']), // Obtener el estado del carrito mini
    formattedCartTotal() {
      return this.cartTotal ? this.cartTotal.toFixed(2) : '0.00';
    },
    formattedTotalItems() {
      return this.totalItems || 0;
    }
  },
  mounted() {
    this.fetchCountries();
    this.formData.items = this.items;
  }
};
</script>