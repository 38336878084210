type ShoppingCartItem = {
  courseId: string,
  courseName: string,
  creatorId: string,
  creatorName: string,
  linkImage: string,
  quantity: number,
  price: number,
  total: number
}

type ShoppingCartState = {
  items: ShoppingCartItem[];
  showMiniCart: boolean;
};

const state: ShoppingCartState = {
  items: [],
  showMiniCart: false,
};

const getters = {
  // Obtiene todos los artículos en el carrito
  items: (state: ShoppingCartState) => state.items,

  // Calcula el total general del carrito
  cartTotal: (state: ShoppingCartState) =>
    state.items.reduce((sum, item) => sum + (item.total || 0), 0),

  // Obtiene la cantidad total de artículos en el carrito
  totalItems: (state: ShoppingCartState) =>
    state.items.reduce((sum, item) => sum + item.quantity, 0),

  // Obtiene el estado del carrito mini
  showMiniCart: (state: ShoppingCartState) => state.showMiniCart,
};

const mutations = {
  setItems(state: ShoppingCartState, items: ShoppingCartItem[]) {
    state.items = items;
  },
  // Agrega un artículo al carrito o actualiza su cantidad
  addItem(state: ShoppingCartState, item: { courseId: string; courseName: string; price: number, creatorId: string, creatorName: string, linkImage: string }) {
    const existingItem = state.items.find((i) => i.courseId === item.courseId);

    let price = 0;
    if(!isNaN(item.price)){
      price = Number(item.price.toFixed(2));
    }
    /* if (existingItem) {
       existingItem.quantity += 1;
       existingItem.total = existingItem.quantity * existingItem.price;
     } else {*/

    if (!existingItem) {
      state.items.push({
        courseId: item.courseId,
        courseName: item.courseName,
        quantity: 1,
        price: price,
        total: price,
        creatorId: item.creatorId,
        creatorName: item.creatorName,
        linkImage: item.linkImage
      });
    }
  },

  // Elimina un artículo del carrito
  removeItem(state: ShoppingCartState, courseId: string) {
    state.items = state.items.filter((item) => item.courseId !== courseId);
  },

  // Actualiza la cantidad de un artículo en el carrito
  updateItemQuantity(
    state: ShoppingCartState,
    payload: { courseId: string; quantity: number }
  ) {
    const item = state.items.find((i) => i.courseId === payload.courseId);

    if (item) {
      item.quantity = payload.quantity;
      item.total = item.quantity * item.price;

      // Si la cantidad es 0, elimina el artículo del carrito
      if (item.quantity <= 0) {
        state.items = state.items.filter((i) => i.courseId !== payload.courseId);
      }
    }
  },

  // Limpia el carrito
  clearCart(state: ShoppingCartState) {
    state.items = [];
  },


  // Cambia el estado de `showMiniCart`
  setShowMiniCart(state: ShoppingCartState, value: boolean) {
    state.showMiniCart = value;
  },
}

const actions = {
  setItems({commit}, items: ShoppingCartItem[]) {
    commit('setItems', items);
  },

  addItem({commit}, item: { courseId: string; price: number }) {
    commit('addItem', item);
  },

  removeItem({commit}, courseId: string) {
    commit('removeItem', courseId);
  },

  updateItemQuantity({commit}, payload: { courseId: string; quantity: number }) {
    commit('updateItemQuantity', payload);
  },

  clearCart({commit}) {
    commit('clearCart');
  },


  openMiniCart({commit}) {
    commit('setShowMiniCart', true);
  },

  closeMiniCart({commit}) {
    commit('setShowMiniCart', false);
  },

  toggleMiniCart({commit, state}) {
    commit('setShowMiniCart', !state.showMiniCart);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}