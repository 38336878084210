<style>
.course__img {
  display: block;
  width: 100%;
}
</style>
<template>
 <section class="page__title-area pt-90 pb-90">
   <div class="page__title-shape">
      <img class="page-title-shape-5 d-none d-sm-block" src="@/assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-6" src="@/assets/img/page-title/page-title-shape-6.png" alt="">
      <img class="page-title-shape-7" src="@/assets/img/page-title/page-title-shape-4.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-8 col-xl-8 col-lg-8">
            <div class="course__wrapper">
               <div class="page__title-content mb-25">
                  <div class="page__title-breadcrumb">                            
                        <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link to="/">{{ $t('menu.home') }}</router-link></li>
                           <li class="breadcrumb-item"><router-link to="/courses">{{ $t('header.courses') }}</router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">{{course?.detail?.title}}</li>
                        </ol>
                        </nav>
                  </div>
                  <span class="page__title-pre">{{course?.detail?.title || 'General'}}</span>
                  <h5 class="page__title-3">{{course?.detail?.title}}</h5>
               </div>
               <div class="course__meta-2 d-sm-flex mb-30">
                  <div class="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                     <div class="course__teacher-thumb-3 mr-15">
                        <img src="@/assets/img/course/teacher/teacher.jpg" alt="">
                     </div>
                     <div class="course__teacher-info-3">
                        <h5>Infoproductor</h5>
                        <p><a href="#">{{course?.teacherName}}</a></p>
                     </div>
                  </div>
                  <div class="course__update mr-80 mb-30">
                     <h5>Last Update:</h5>
                     <p>July 24, 2022</p>
                  </div>
                  <div class="course__rating-2 mb-30">
                     <h5>Review:</h5>
                     <div class="course__rating-inner d-flex align-items-center">
                        <ul>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                        </ul>
                        <p>{{course?.rating || 5}}</p>
                     </div>
                  </div>
               </div>
               <div class="course__img mb-30">
                 <img :src="course?.coverLinks?.fileLink?? '@/assets/img/course/details/course-details-1.jpg'" alt="" v-if="course.coverLinks">
               </div>
               <div class="course__tab-2 mb-45">
                  <ul class="nav nav-tabs" id="courseTab" role="tablist">
                     <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true"> <i class="icon_ribbon_alt"></i> <span>Discription</span> </button>
                     </li>
                     <li class="nav-item" role="presentation">
                        <button class="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i class="icon_book_alt"></i> <span>Curriculum</span> </button>
                     </li>
                     <li class="nav-item" role="presentation">
                        <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false"> <i class="icon_star_alt"></i> <span>Reviews</span> </button>
                     </li>
                     </ul>
               </div>
               <div class="course__tab-content mb-95">
                  <div class="tab-content" id="courseTabContent">
                     <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                        <div class="course__description">
                           <h3>Course Overview</h3>
                          <p>{{course?.detail?.description}}</p>
                           <div class="course__tag-2 mb-35 mt-35">
                              <i class="fal fa-tag"></i>
                              <a href="#">{{course?.detail?.category}}</a>
                           </div>
                           <div class="course__description-list mb-45">
                              <h4>What is the Target Audience?</h4>
                              <ul>
                                 <li> <i class="icon_check"></i> Business's managers, leaders</li>
                                 <li> <i class="icon_check"></i> Downloadable lectures, code and design assets for all projects</li>
                                 <li> <i class="icon_check"></i> Anyone who is finding a chance to get the promotion</li>
                              </ul>
                           </div>
                           <div class="course__instructor mb-45">

                           </div>
                        </div>
                     </div>

                     <!-- CurriculumTab start -->
                     <CurriculumTab/>
                     <!-- CurriculumTab end -->

                     <!-- CurriculumTab start -->
                     <ReviewTab/>
                     <!-- CurriculumTab end -->

                     <!-- CurriculumTab start -->

                     <!-- CurriculumTab end -->
                   
                     <div class="course__share">
                        <h3>Share :</h3>
                        <ul>
                           <li>
                              <a href="#" class="fb" ><i class="fab fa-facebook-f"></i>
                           </a></li>
                           <li>
                              <a href="#" class="tw" ><i class="fab fa-twitter"></i>
                           </a></li>
                           <li>
                              <a href="#" class="pin" ><i class="fab fa-pinterest-p"></i>
                           </a></li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="course__related">
                  <div class="row">
                     <div class="col-xxl-12">
                        <div class="section__title-wrapper mb-40">
                           <h2 class="section__title">Related <span class="yellow-bg yellow-bg-big">Course<img src="@/assets/img/shape/yellow-bg.png" alt=""></span></h2>
                           <p>You don't have to struggle alone, you've got our assistance and help.</p>
                        </div>
                     </div>
                  </div>
                  <!-- slider courses start-->
                  <SliderCourses/>
                  <!-- slider courses end-->
               </div>
            </div>
         </div>

       <!-- course side bar start -->
       <CourseSidebar :course="course" />
       <!-- course side bar end -->

      </div>
   </div>
</section>
</template>

<script lang="ts">
import SliderCourses from './SliderCourses.vue';
import CourseSidebar from './CourseSidebar.vue';
import CurriculumTab from './CurriculumTab.vue';
import ReviewTab from './ReviewTab.vue';
import {defineComponent} from "vue";
import {Category} from "@/core/domain/entities/category.entity";
import {Course} from "@/core/domain/entities/course.entity";
import {CoursesUseCase} from "@/core/usecases/courses.usecase";
import {useStore} from "vuex";
import {key} from "@/infrastructure/di/store";

export default defineComponent({
   name:'CourseDetailsArea',
   components:{
      SliderCourses,
      CourseSidebar,
      CurriculumTab,
      ReviewTab,
   },
  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
  data() {
    return {
      selectedCategory: {} as Category,
      id: this.$route.params.id,
      course: {} as Course,
      currentVideo: '',
      showVideo: false
    }
  },
  methods: {
    async getCourse() {
      const useCase = new CoursesUseCase(this.store);
      if (this.id) {
        const c = await useCase.getCourse(this.id as string, this.$i18n.locale);
        if (c) {
          this.course = c;
          this.currentVideo = c?.coverLinks?.fileLink ?? require(`@/assets/img/course/curso_defi.png`);
        }
        console.log('coursee ', this.course);
      }
    }
  },
  async mounted() {
    console.log(this.$route.params.id);
    await this.getCourse();
  },
});
</script>