import Vuex, {createStore, Store} from 'vuex';
import Vue from 'vue';

import categoriesModule from '../store/modules/categories.module';
import coursesModule from '../store/modules/courses.module';
import uxModule from '../store/modules/ux.module';
import userModule from '@/infrastructure/di/store/modules/user.module';
import lecturesModule from '@/infrastructure/di/store/modules/lectures.module';
import shoppingCart from '@/infrastructure/di/store/modules/shoppingCart.module';
import {InjectionKey} from 'vue';

const key: InjectionKey<Store<unknown>> = Symbol();

const store = createStore({
  modules: {
    categoriesModule,
    coursesModule,
    uxModule,
    userModule,
    lecturesModule,
    shoppingCart
  }
});

export {
  key,
  store
}