<template>
  <section class="cart-area pt-60 pb-100">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="table-content table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th class="product-thumbnail">{{ $t("cart.tableHeaders.images") }}</th>
                <th class="cart-product-name">{{ $t("cart.tableHeaders.product") }}</th>
                <th class="product-price">{{ $t("cart.tableHeaders.unitPrice") }}</th>
                <th class="product-quantity">{{ $t("cart.tableHeaders.quantity") }}</th>
                <th class="product-subtotal">{{ $t("cart.tableHeaders.total") }}</th>
                <th class="product-remove">{{ $t("cart.tableHeaders.remove") }}</th>
              </tr>
              </thead>
              <tbody class="border-0">
              <tr v-for="item in items" :key="item.courseId">
                <td class="product-thumbnail">
                  <router-link :to="`/course-details/${item.courseId}`">
                    <img :src="item.linkImage" alt="" v-if="item.linkImage">
                  </router-link>
                </td>
                <td class="product-name">
                  <router-link :to="`/course-details/${item.courseId}`">{{ item.courseName }}</router-link>
                </td>
                <td class="product-price"><span class="amount">${{ item.price.toFixed(2) }}</span></td>
                <td class="product-quantity text-center">
                  <div class="product-quantity mt-10 mb-10">
                    <div class="product-quantity-form">
                      <button class="cart-minus" @click="updateQuantity(item.courseId, item.quantity - 1)">
                        <i class="far fa-minus"></i>
                      </button>
                      <input class="cart-input" type="text" :value="item.quantity" readonly />
                      <button class="cart-plus" @click="updateQuantity(item.courseId, item.quantity + 1)">
                        <i class="far fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </td>
                <td class="product-subtotal">
                  <span class="amount">${{ item.total.toFixed(2) }}</span>
                </td>
                <td class="product-remove">
                  <button @click="removeItem(item.courseId)">
                    <i class="fa fa-times"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="coupon-all">
                <div class="coupon d-sm-flex align-items-center">
                  <input id="coupon_code" class="input-text" name="coupon_code" value="" :placeholder="$t('cart.coupon.placeholder')" type="text">
                  <button class="e-btn" name="apply_coupon" type="submit">{{ $t("cart.coupon.apply") }}</button>
                </div>
                <div class="coupon2">
                  <button class="e-btn" name="update_cart" type="submit">{{ $t("cart.coupon.update") }}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 ml-auto">
              <div class="cart-page-total">
                <h2>{{ $t("cart.total.title") }}</h2>
                <ul class="mb-20">
                  <li>{{ $t("cart.total.subtotal") }} <span>${{ cartTotal.toFixed(2) }}</span></li>
                  <li>{{ $t("cart.total.total") }} <span>${{ cartTotal.toFixed(2) }}</span></li>
                </ul>
                <router-link class="e-btn e-btn-border" to="/checkout">{{ $t("cart.total.checkout") }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CartArea",
  computed: {
    ...mapGetters("shoppingCart", ["items", "cartTotal"]),
  },
  methods: {
    ...mapActions("shoppingCart", ["removeItem", "updateItemQuantity"]),
    updateQuantity(courseId, quantity) {
      if (quantity >= 0) {
        this.updateItemQuantity({ courseId, quantity });
      }
    },
  },
};
</script>

<style scoped>
/* Agrega aquí tus estilos específicos del componente */
</style>