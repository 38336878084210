<template>
<header>
   <div id="header-sticky" :class="`${isSticky ? 'header__area header__transparent header__padding-2 sticky' : `${headerShadow ? headerShadow : 'header__area header__transparent header__padding-2'}`}`">
      <div class="container">
         <div class="row align-items-center">
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
               <div class="header__left d-flex">
                  <div class="logo">
                     <router-link to="/">
                        <img src="@/assets/img/logo/bullfy.png" alt="logo">
                     </router-link>
                  </div>
                  <div class="header__category d-none d-lg-block">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</header>

<!-- sidebar area start -->

  <!-- sidebar area end -->
</template>

<script>
export default {
   name:'HomeTwoHeader',
   data () {
    return {
      isSticky:false,
      showSidebar:false,
      menuOption: {
      menuShow: false,
      menuSearch: false,
      homeDropdown: false,
      coursesDropdown: false,
      pagesDropDown: false,
      blogDropdown: false,
      aboutDropdown: false,
   },
    }
  },
  props:{
    headerShadow:String,
  },
  methods:{
      handleSticky () {
          if(window.scrollY > 80){
             this.isSticky = true;
          }
          else{
             this.isSticky = false;
          }
       },
       
       handleSidebar () {
         this.showSidebar = true;
       },
       handleSidebarClose () {
         this.showSidebar = false;
       },
  },
  mounted() {
      window.addEventListener('scroll',this.handleSticky)
   },
};
</script>