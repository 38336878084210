import {IAuthRepository} from '@/core/domain/repositories/auth.repository';
import {IAuthDataSource} from '@/core/domain/datasources/auth.datasource';
import {AuthDataSource} from '@/infrastructure/data/datasources/auth.datasource.impl';
import {User} from '@/core/domain/entities/user.entity';
import {InstructorInputModel} from '@/infrastructure/data/models/instructorInput.model';

export class AuthRepository implements IAuthRepository {
    private _dataSource: IAuthDataSource;

    constructor() {
        this._dataSource = new AuthDataSource();
    }

    async register(email: string, password: string, fullName: string, role: string): Promise<AuthToken> {
        return await this._dataSource.register(email, password, fullName, role);
    }

    async registerInstructor(body: InstructorInputModel): Promise<boolean> {
        body.fullName = `${body.firstName} ${body.lastName}`
        return await this._dataSource.registerInstructor(body);
    }

    async getUserData(): Promise<User> {
        return await this._dataSource.getUserData();
    }

    async login(email: string, password: string): Promise<AuthToken | null> {
        return await this._dataSource.login(email, password);
    }

    async refreshToken(refreshToken: string): Promise<AuthToken> {
        return await this._dataSource.refreshToken(refreshToken);
    }

    async validateUser(email: string): Promise<any> {
        return await this._dataSource.validateUser(email);
    }
}