<template>
  <section class="page__title-area page__title-height page__title-overlay d-flex align-items-center"
           :style="{ background: 'url(' + require('@/assets/img/page-title/page-title-3.jpg') + ')', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xxl-12 position-relative">
          <div class="page__title-wrapper mt-110" style="left: 60%;">
            <h3 class="page__title mb-4">{{ title }}</h3>
          </div>
        </div>


      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: String,
  }
};
</script>